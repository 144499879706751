<template>
  <base-cdek-modal :name="modalName" @close="close">
    <cdek-auth
      :notification-text="notificationText"
      :alert="alert"
      :allow-login="allowLogin"
      :is-redirect="isRedirect"
      :legal-only="legalOnly"
      @success="onSuccess"
    />
  </base-cdek-modal>
</template>

<script lang="ts" setup>
import CdekAuth from '@/components/Auth/CdekAuth.vue';
import useModal from '@/composables/use-modal';
import useLoadUser from '@/components/Login/use-load-user';
import { modalName } from '@/components/Login/consts';
import BaseCdekModal from '@/components/BaseComponents/BaseCdekModal.vue';

interface LoginAlert {
  title: string;
  url: string;
  target: string;
}

interface IProps {
  allowLogin?: boolean;
  withLoadUser: boolean;
  isRedirect: boolean;
  notificationText: string;
  alert?: LoginAlert;
  legalOnly?: boolean;
}

const props = withDefaults(defineProps<IProps>(), { allowLogin: true });

const modal = useModal();
const userLoader = useLoadUser();

const emit = defineEmits<{
  (e: 'success'): void;
}>();

const close = () => {
  modal.hide(modalName);
};

const onSuccess = async () => {
  if (props.withLoadUser) {
    await userLoader.load();
  }

  emit('success');
  modal.hide(modalName);
};
</script>

<style lang="scss" scoped>
.login-modal {
  display: flex;
  align-items: center;

  &__content {
    position: relative;
    margin: auto;
    background: #fff;
    padding: 32px;
    border-radius: 10px;
    box-shadow: 0 20px 60px -2px rgba(27, 33, 58, 0.4);
    max-width: 400px;
  }

  &.v--modal {
    padding: 32px 40px;
    border-radius: 10px;
  }

  &__close {
    padding: 22px 18px;
    outline: none;
    line-height: 0;
  }

  &__action {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__container {
    max-width: 320px;
  }
}
</style>
