export function roundNumber(number, approximation) {
  number = parseFloat(number);
  const int = parseInt(number / approximation, 10);
  return int * approximation + approximation;
}

export function getRoundedTime(
  timeString,
  approximation,
  minHours,
  maxHours,
  minMinutes = 0,
  maxMinutes = 0,
) {
  if (timeString) {
    const [hours, minutesStr] = timeString.split(':');
    let minutes = minutesStr;

    if (
      maxHours > parseFloat(hours) &&
      minHours < parseFloat(hours) &&
      parseFloat(minutes) % approximation > 0
    ) {
      minutes = `0${roundNumber(minutes, approximation)}`.slice(-2);
      return `${hours}:${minutes}`;
    }
    if (maxHours <= parseFloat(hours)) {
      if (parseFloat(minutes) < maxMinutes) {
        minutes =
          parseFloat(minutes) % approximation > 0
            ? `0${roundNumber(minutes, approximation)}`.slice(-2)
            : minutes;
      } else {
        minutes = `0${maxMinutes}`.slice(-2);
      }
      return `${maxHours}:${minutes}`;
    }
    if (minHours > parseFloat(hours)) {
      const h = `0${minHours}`.slice(-2);
      const m = `0${minMinutes}`.slice(-2);
      return `${h}:${m}`;
    }
    if (minHours === parseFloat(hours)) {
      if (minMinutes > parseFloat(minutes)) {
        const h = `0${minHours}`.slice(-2);
        const m = `0${minMinutes}`.slice(-2);
        return `${h}:${m}`;
      }
      if (parseFloat(minutes) % approximation > 0) {
        minutes = `0${roundNumber(minutes, approximation)}`.slice(-2);
        return `${hours}:${minutes}`;
      }
    }
  }
  return timeString;
}

export function getCurrentTime(deadline, currentToMs) {
  const total = Date.parse(deadline) - currentToMs;
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));
  if (total > 0) {
    return {
      total,
      seconds,
      minutes,
      hours,
      days,
    };
  }
  return null;
}
