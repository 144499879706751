export const RETURNS = {
  CLIENT_RETURN_TRACING_SITE: 'client_return_tracing_site',
} as const;

export const ADVERT_AGREEMENT = {
  LK_MAIL: 'lk_mail',
  LK_MAIL_AD_CONFIRM: 'lk_mail_ad_confirm',
  LK_AD: 'lk_ad',
  LK_AD_CONFIRM: 'lk_ad_confirm',
} as const;

export const GIFT = {
  GIFTS_CLICK: 'gifts_click',
} as const;

export const enum CdekIdGoal {
  TINKOFF = 'cdekid_tinkoff',
  ALFA_SITE = 'cdekid_alfa_site',
  MOBILEID_SITE = 'cdekid_mobileid_site',
  FORM_SITE = 'cdekid_form_site',
}
