import { TildaLS } from '@/types/dto/tilda';

const LS_TILDA_KEY = 'cdek-tilda';

export default function useTildaUser() {
  const saveLS = (payload: TildaLS) => {
    localStorage?.setItem(LS_TILDA_KEY, JSON.stringify(payload));
  };

  const removeLS = () => {
    localStorage?.removeItem(LS_TILDA_KEY);
  };

  return {
    removeLS,
    saveLS,
  };
}
