<template>
  <div class="cdek-auth">
    <div v-if="isLoading" class="cdek-auth__loader">
      <base-loader big :old="false" />
    </div>
    <div v-show="!isLoading" id="__cdek-auth" />
  </div>
</template>

<script>
/* global CdekAuth */
import { mapGetters, mapMutations } from 'vuex';
import { reachGoal } from '@/utils/reach-goal';
import BaseLoader from '@/components/BaseLoader.vue';
import { castEnv } from '@/utils/env-utils';
import { useRedirect } from '@/components/Login/use-redirect';
import useSiteInfo from '@/composables/use-site-info';

export default {
  name: 'CdekAuth',
  components: { BaseLoader },
  props: {
    notificationText: {
      type: String,
      default: '',
    },
    /**
     * @param {string} title
     * @param {string} url
     * @param {string} target
     */
    alert: {
      type: Object,
      default: null,
    },
    allowLogin: {
      type: Boolean,
      default: true,
    },
    isRedirect: {
      type: Boolean,
      default: false,
    },
    legalOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['success'],
  setup() {
    const { currentLocale, isRuSite } = useSiteInfo();

    return { currentLocale, handleCabinetRedirect: useRedirect(), isRuSite };
  },
  data() {
    return {
      isLoading: typeof CdekAuth === 'undefined',
      captchaKey: import.meta.env.VITE_CAPTCHA_KEY_PUBLIC,
      scriptUrl: import.meta.env.VITE_CDEK_AUTH_SCRIPT_URL,
      httpEndpoint: import.meta.env.VITE_HTTP_ENDPOINT || window.location.origin,
      isRedirectEnabled: castEnv(import.meta.env.VITE_CDEK_AUTH_REDIRECT_ENABLED, false),
      testRedirect: import.meta.env.VITE_CDEK_AUTH_TEST_REDIRECT,
      availableCodes: import.meta.env.VITE_CDEK_AUTH_AVAILABLE_CODES || ['ru', 'kz', 'by'],
    };
  },
  computed: {
    ...mapGetters({
      websiteId: 'websiteId',
    }),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setKeycloak: 'keycloak/setKeycloak',
    }),
    loadScript() {
      const params = {
        async: true,
        src: this.scriptUrl,
        onload: () => {
          this.init();
        },
      };
      const script = Object.assign(document.createElement('script'), params);
      document.body.appendChild(script);
    },
    init() {
      if (typeof CdekAuth === 'undefined') {
        this.loadScript();
        return;
      }

      this.isLoading = false;

      const o = CdekAuth({
        id: '#__cdek-auth',
        locale: this.currentLocale,
        websiteId: this.websiteId,
        urlLegalEntitiesLk: import.meta.env.VITE_LKUL_URL || 'https://lk.cdek.ru/',
        httpEndpoint: this.httpEndpoint,
        captchaKeyPublic: this.captchaKey,
        allowLogin: this.allowLogin,
        yandexMetrikaId: import.meta.env.VITE_YANDEX_METRIKA_ID || null,
        allowLegalLogin: this.isRuSite,
        legalOnly: this.legalOnly,
      });

      if (this.notificationText) {
        o.setNotification(this.notificationText);
      }

      if (this.alert) {
        o.setAlert(this.alert);
      }

      const urlParams = new URLSearchParams(window.location.search);
      const phone = urlParams.get('phone');
      const token = urlParams.get('token');

      if (phone && phone.match(/^\+?([\d]{11,12})$/)) {
        o.setPhone(phone, false, true);
      }

      if (token) {
        o.setCodeToken(token);
      }

      if (this.isRedirectEnabled) {
        o.onRedirect((data) => {
          let redirectTo = data.redirectUrl;

          // Для тестовых сред
          if (this.testRedirect === 'origin') {
            redirectTo = window.location.origin;
          } else if (this.testRedirect) {
            redirectTo = this.testRedirect;
          }

          const url = new URL('/cabinet/auth', redirectTo);
          url.searchParams.set('phone', data.phone);
          url.searchParams.set('token', data.token);

          window.location.replace(url);
        }).stopIfRedirect();
      }

      if (this.availableCodes && o.setAvailableCountryCodes) {
        o.setAvailableCountryCodes(this.availableCodes);
      }

      o.onAuth(this.onSuccess).mount();
    },
    async onSuccess(data, phone) {
      const { mindbox } = window;

      if (mindbox) {
        //  Кусок кода из задачи
        mindbox('async', {
          operation: 'Website.AuthenticatedOnWebsite',
          data: { customer: { mobilePhone: phone } },
        });
      }

      await this.storeInitAuth(data, phone);
      this.$emit('success');

      reachGoal('vhod v lk phiz l');

      if (this.isRedirect) {
        setTimeout(() => this.handleCabinetRedirect(), 100);
      }
    },
    /**
     * @param {KeycloakResponse} data
     *
     * @returns {Promise}
     */
    storeInitAuth(data) {
      return new Promise((resolve) => {
        this.setKeycloak(data);

        resolve();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cdek-auth {
  &__loader {
    text-align: center;
  }
}
</style>
