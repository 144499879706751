<template>
  <vue-final-modal
    content-class="confirm-code-email-modal base-modal--confirm-code-email"
    :name="name"
    :classes="['vfm-modal-container']"
  >
    <div class="v-modal-confirm-code-email-modal">
      <div class="width-wrapper">
        <template v-if="!confirmSuccess">
          <base-loader v-if="confirmLoading" class="confirm-code-email-modal__loader" />
          <icon-close class="confirm-code-email-modal__close" @click="close" />
          <h2 class="confirm-code-email-modal__title">
            {{ $t('confirmEmailModal.emailConfirm') }}
          </h2>
          <p class="confirm-code-email-modal__text">{{ $t('confirmEmailModal.emailCode') }}</p>
          <div class="confirm-code-email-modal__field-code">
            <div v-if="confirmError" class="confirm-code-email-modal__field-code-error">
              {{ $t('confirmEmailModal.emailCodeError') }}
            </div>
            <base-input
              ref="numb1"
              v-model="form.numb1"
              v-test-id="'email-confirm-code1'"
              inputmode="numeric"
              class="confirm-code-email-modal__field-code-input"
              :old="false"
              :clear-button="false"
              :disabled="confirmLoading"
              :has-error="confirmError"
              @input="(e) => onChangeCode(e, 'numb1')"
              @keydown="(e) => onKeyDown(e, 'numb1')"
            />
            <base-input
              ref="numb2"
              v-model="form.numb2"
              v-test-id="'email-confirm-code2'"
              inputmode="numeric"
              class="confirm-code-email-modal__field-code-input"
              :old="false"
              :clear-button="false"
              :disabled="confirmLoading"
              :has-error="confirmError"
              @input="(e) => onChangeCode(e, 'numb2')"
              @keydown="(e) => onKeyDown(e, 'numb2')"
            />
            <base-input
              ref="numb3"
              v-model="form.numb3"
              v-test-id="'email-confirm-code3'"
              inputmode="numeric"
              class="confirm-code-email-modal__field-code-input"
              :old="false"
              :clear-button="false"
              :disabled="confirmLoading"
              :has-error="confirmError"
              @input="(e) => onChangeCode(e, 'numb3')"
              @keydown="(e) => onKeyDown(e, 'numb3')"
            />
            <base-input
              ref="numb4"
              v-model="form.numb4"
              v-test-id="'email-confirm-code4'"
              inputmode="numeric"
              class="confirm-code-email-modal__field-code-input"
              :old="false"
              :clear-button="false"
              :disabled="confirmLoading"
              :has-error="confirmError"
              @input="(e) => onChangeCode(e, 'numb4')"
              @keydown="(e) => onKeyDown(e, 'numb4')"
            />
          </div>
          <div class="confirm-code-email-modal__actions">
            <base-button
              v-test-id="'email-confirm-code-btn'"
              :loading="sendCodeLoading"
              :disabled="disabledButtonResetCode"
              primary
              class="base-button--block"
              @click="onResetCode"
            >
              <template v-if="disabledButtonResetCode">
                <i18n-t keypath="confirmEmailModal.sendEmailAgain">
                  <template #time>
                    <timer
                      v-if="disabledButtonResetCode"
                      :current="currentTime"
                      :deadline="deadlineTime"
                      @stop-timer="stopTimer"
                    />
                  </template>
                </i18n-t>
              </template>
              <template v-else> {{ $t('confirmEmailModal.sendEmailAgainBtn') }} </template>
            </base-button>
          </div>
        </template>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import { useToast } from '@cdek-ui-kit/vue';
import { cdekConsoleError } from '@/utils/console-wrapper';
import useCabinetApi from '@/composables/use-cabinet-api';
import Timer from '@/components/Timer.vue';
import IconClose from './assets/close-green.svg';
import BaseLoader from '../BaseComponents/BaseLoader.vue';
import BaseButton from '../BaseComponents/BaseButton.vue';
import BaseInput from '../BaseComponents/BaseInput.vue';

export default {
  name: 'EmailConfirmCodeModal',

  components: {
    BaseLoader,
    BaseButton,
    BaseInput,
    IconClose,
    Timer,
  },

  props: {
    email: {
      type: String,
      default: '',
    },
  },

  setup() {
    const { apiClient: api } = useCabinetApi();

    return {
      toast: useToast(),
      api,
    };
  },

  data() {
    const currentTime = new Date();
    return {
      name: 'confirm-code-email-modal',
      form: {
        numb1: '',
        numb2: '',
        numb3: '',
        numb4: '',
      },
      currentTime,
      deadlineTime: moment(currentTime).add(1, 'minutes').format(),
      disabledButtonResetCode: true,
      confirmLoading: false,
      confirmSuccess: false,
      confirmError: false,
      sendCodeLoading: false,
      isCodeValid: false,
    };
  },

  computed: {
    ...mapGetters({
      userEmail: 'cabinet/getUserEmail',
    }),
    code() {
      return [this.form.numb1, this.form.numb2, this.form.numb3, this.form.numb4].join('');
    },
    isCodeFull() {
      return this.code.length === 4;
    },
  },

  methods: {
    ...mapMutations({
      setUserEmailConfirmed: 'cabinet/setUserEmailConfirmed',
      setUserEmail: 'cabinet/setUserEmail',
    }),
    async sendCodeToEmail() {
      const email = this.userEmail;

      try {
        this.sendCodeLoading = true;
        await this.api.setEmail(email);
      } catch (e) {
        cdekConsoleError(e.message);
      } finally {
        this.sendCodeLoading = false;
      }
    },
    onFocus() {
      if (!this.form.numb1) {
        this.$refs.numb1.$refs.field.focus();
      } else if (!this.form.numb2) {
        this.$refs.numb2.$refs.field.focus();
      } else if (!this.form.numb3) {
        this.$refs.numb3.$refs.field.focus();
      } else if (!this.form.numb4) {
        this.$refs.numb4.$refs.field.focus();
      }
    },
    onFocusByNumb(numb) {
      this.$refs[numb].$refs.field.focus();
    },
    onBlur() {
      this.$refs.numb1.$refs.field.blur();
      this.$refs.numb2.$refs.field.blur();
      this.$refs.numb3.$refs.field.blur();
      this.$refs.numb4.$refs.field.blur();
    },
    stopTimer() {
      this.disabledButtonResetCode = false;
    },
    runTimer() {
      this.currentTime = new Date();
      this.deadlineTime = moment(this.currentTime).add(1, 'minutes').format();
    },
    onResetCode() {
      this.sendCodeToEmail();
      this.runTimer();
      this.disabledButtonResetCode = true;
      this.onBlur();
    },
    onChangeCode(value = '', field = '') {
      const onlyNumb = value.replace(/^[a-zA-Z!@#$%^&*)(+=._-]+$/g, '').trim();
      if (onlyNumb.length > 1) {
        this.form[field] = onlyNumb[onlyNumb.length - 1];
      } else {
        this.form[field] = onlyNumb;
      }
      const nextField = parseFloat(field.replace('numb', '')) + 1;
      if (nextField < 5) {
        this.onFocusByNumb(`numb${nextField}`);
      }
      this.checkCode();
    },
    onKeyDown(event, field) {
      if (event.keyCode === 8) {
        event.preventDefault();
        const prevField = parseFloat(field.replace('numb', '')) - 1;
        this.form[field] = '';
        this.form[`numb${prevField}`] = '';
        if (prevField > 0) {
          this.onFocusByNumb(`numb${prevField}`);
        }
      }
    },
    checkCode() {
      setTimeout(() => {
        if (this.isCodeFull) {
          this.confirmEmail(this.code);
        }
      });
    },
    async confirmEmail(code) {
      try {
        this.confirmError = false;
        this.confirmLoading = true;
        const email = this.email || this.userEmail;
        await this.api.confirmEmail(email, code);
        this.setUserEmail(email);
        this.setUserEmailConfirmed(true);

        this.confirmSuccess = true;

        const isMobile = window?.matchMedia('(max-width: 768px)');
        this.toast.success(
          {
            title: this.$t('confirmEmailModal.emailConfirmSuccess'),
          },
          {
            position: isMobile.matches ? 'top-center' : 'top-right',
          },
        );
        this.close();
      } catch (e) {
        this.confirmError = true;
        this.clearCode();
        cdekConsoleError(e);
      } finally {
        this.confirmLoading = false;
      }
    },
    clearCode() {
      this.form.numb1 = '';
      this.form.numb2 = '';
      this.form.numb3 = '';
      this.form.numb4 = '';
      this.onBlur();
    },
    close() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="scss">
.confirm-code-email-modal {
  position: relative;

  &__loader {
    position: absolute;
    z-index: 10;
    bottom: calc(50% - 52px);
    left: calc(50% - 10px);
  }

  &__title {
    margin-bottom: 4px;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  &__text {
    margin-bottom: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  &__actions {
    button {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__success {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    svg {
      margin-bottom: 20px;
    }
  }

  &__field-code {
    position: relative;
    display: flex;
    margin-bottom: 40px;
    gap: 16px;
    justify-content: space-between;

    &:last-child {
      margin-right: 0;
    }

    &-input {
      .base-control__field {
        width: 66px;
        height: 66px;
        text-align: center;
        border-width: 2px;
        font-size: 40px;
        font-style: normal;
        font-weight: 300;
        line-height: 50px;
        padding: 18px;
        border-radius: 8px;
        border: 2px solid var(--bottom-bottom-20, rgba(0, 0, 0, 0.2));

        @include media-min-sm {
          min-width: 84px;
          min-height: 84px;
        }
      }
    }

    &-error {
      position: absolute;
      right: 0;
      bottom: -17px;
      left: 0;
      flex: 1 1 100%;
      color: $ui-red-base;
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__close {
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}

.v-modal-confirm-code-email-modal {
  padding: 24px;
}

.base-modal--confirm-code-email {
  border-radius: 16px;
  background: $Peak;
  box-shadow: 0 20px 40px -5px rgba(0, 0, 0, 0.2);
  padding: 0;
  width: 100%;

  @include media-min-sm {
    width: 432px;
  }
}
</style>

<style lang="scss" scoped>
.base-button--primary {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  padding: 12px;
  cursor: pointer;
}

.base-button--primary:disabled:not(.base-button--loading) {
  color: $Peak;
  background-color: $Button_Disable;
}

.base-control {
  width: auto;
}
</style>
