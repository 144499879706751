import IMenuItem from '@/types/dto/menuItem';
import { CABINET_CALCULATE } from '@/components/NewOrder/constants';

export const changeSiteUrls = (node: IMenuItem, locale = 'ru') => {
  const newNode = { ...node };

  if (newNode.url.endsWith('tracking/')) {
    newNode.url = `/${locale}/cabinet/orders`;
    newNode.isExternal = true;
  } else if (newNode.url.endsWith('cdekid/') || newNode.title === 'CDEK ID') {
    newNode.url = `/${locale}/cabinet/cdekid`;
    newNode.isExternal = true;
  } else if (newNode.url.endsWith(`calculate/`) || newNode.url.endsWith('courier/')) {
    newNode.url = `/${locale}${CABINET_CALCULATE}`;
    newNode.isExternal = true;
  }

  if (!(newNode.children && newNode.children.length)) {
    return newNode;
  }

  newNode.children = newNode.children.map((el) => changeSiteUrls(el));
  return newNode;
};
