<template>
  <span v-if="old" class="base-loader" />
  <icon-progress v-else :big="big" :primary="primary" :secondary="secondary" />
</template>

<script>
import IconProgress from '@/components/icon-svg/IconProgress.vue';

export default {
  components: { IconProgress },
  props: {
    old: {
      type: Boolean,
      default() {
        return true;
      },
    },
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.rotate {
  animation: rotation 2s infinite linear;
}

.rotate-progress {
  display: block;
  width: 24px;
  height: 24px;
  background-image: url('@/assets/img/progress-circle.png');
  background-size: cover;

  @extend .rotate;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}

.base-loader {
  &::after {
    content: ' ';
    display: inline-block;

    @extend .rotate-progress;
  }
}
</style>
