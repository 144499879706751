<template>
  <div :id="id" class="mobile-close-line">
    <div class="mobile-close-line__trigger" />
  </div>
</template>

<script>
export default {
  name: 'MobileCloseLine',
  emits: ['swipe-up', 'swipe-down'],
  data() {
    return {
      // В пределах одной страницы может быть несколько таких линий.
      id: `tocuh-${Math.floor(Math.random() * 10000)}`,
    };
  },
  mounted() {
    const touchEl = document.querySelector(`#${this.id}`);

    if (touchEl) {
      import('hammerjs').then(({ default: Hammer }) => {
        const hammer = new Hammer(touchEl);
        hammer.get('swipe').set({ direction: Hammer.DIRECTION_VERTICAL });

        hammer.on('swipe', (e) => {
          const isSwipeDown = e.deltaY > 0;
          isSwipeDown ? this.$emit('swipe-down') : this.$emit('swipe-up');
        });
      });
    }
  },
};
</script>

<style scoped lang="scss">
.mobile-close-line {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;

  @media (min-width: $desktop) {
    display: none;
  }

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 4px;
    border-radius: 100px;
    background-color: rgba(29, 29, 27, 0.3);
  }
}
</style>
