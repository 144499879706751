<template>
  <span>{{ timeLeft }}</span>
</template>

<script>
import { getCurrentTime } from '@/utils/time';

export default {
  name: 'Timer',
  props: {
    current: {
      type: [Date, String],
      default() {
        return new Date();
      },
    },
    deadline: {
      type: String,
      required: true,
    },
    speed: {
      type: Number,
      default: 1000,
    },
  },
  emits: ['increaseCurrentTime', 'stopTimer'],
  data() {
    return {
      currentTime: null,
      clearTimer: null,
      currentToMs: 0,
    };
  },
  computed: {
    timeLeft() {
      if (this.currentTime) {
        const h = `0${this.currentTime.hours}`.slice(-2);
        const m = `0${this.currentTime.minutes}`.slice(-2);
        const s = `0${this.currentTime.seconds}`.slice(-2);
        if (this.currentTime.hours) {
          return `${h}:${m}:${s}`;
        }
        return `${m}:${s}`;
      }
      return '';
    },
  },
  mounted() {
    this.currentToMs = Date.parse(this.current);
    setTimeout(() => this.countdown(), 1);
  },
  beforeUnmount() {
    if (this.clearTimer) {
      clearTimeout(this.clearTimer);
    }
  },
  methods: {
    countdown() {
      const current = getCurrentTime(this.deadline, this.currentToMs);
      if (current && current.total > 0) {
        this.currentTime = current;
        this.clearTimer = setTimeout(() => {
          this.currentToMs += this.speed;
          this.$emit('increaseCurrentTime', this.currentToMs);
          this.countdown();
        }, this.speed);
      } else {
        this.currentTime = null;
        this.$emit('stopTimer');
      }
    },
  },
};
</script>
