import { computed } from 'vue';
import { useStore } from 'vuex';
import useModal from '@/composables/use-modal';
import useSiteInfo from '@/composables/use-site-info';
import { useLocalStorage } from '@vueuse/core';
import EmailFillModal from '@/components/Modal/EmailFillModal.vue';
import AdvertAgreementModal from '@/components/Modal/AdvertAgreementModal.vue';
import useAdvertAgreement from './use-advert-agreement';

const ADVERT_AGREEMENT_STORAGE_KEY = 'advert-agreement-status';
const ADVERT_AGREEMENT_TIMEOUT_PERIOD = 12 * 3600 * 1000; // 12 часов

export default function useEmailOnLogin() {
  const store = useStore();
  const modal = useModal();
  const { isRuSite } = useSiteInfo();
  const advertAgreement = useAdvertAgreement();
  const nextTimeAdvert = useLocalStorage<number>(ADVERT_AGREEMENT_STORAGE_KEY, 0);
  const email = computed(() => store.getters['cabinet/getUserEmail']);

  const check = async () => {
    if (!email.value) {
      modal.show({
        component: EmailFillModal,
      });
    } else if (isRuSite.value && nextTimeAdvert.value < Date.now()) {
      const data = await advertAgreement.loadStatus();
      nextTimeAdvert.value = Date.now() + ADVERT_AGREEMENT_TIMEOUT_PERIOD;

      if (data?.needRequestAgreement) {
        modal.show({
          component: AdvertAgreementModal,
        });
      }
    }
  };

  return {
    check,
  };
}
