<template>
  <svg :width="width" :height="height" :class="{ 'icon-progress--big': big }">
    <circle :cx="cx" :cy="cy" :r="r" :stroke="colors.progress" />
  </svg>
</template>

<script>
export default {
  name: 'IconProgress',
  props: {
    primary: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colors() {
      if (this.primary) {
        return {
          stroke: 'transparent',
          progress: '#FFFFFF',
        };
      }
      if (this.secondary) {
        return {
          stroke: 'transparent',
          progress: '#00A942',
        };
      }
      return {
        stroke: '#EDF0F3',
        progress: '#00A942',
      };
    },
    width() {
      return this.big ? 86 : 20;
    },
    height() {
      return this.big ? 86 : 20;
    },
    cx() {
      return this.big ? 38 : 9;
    },
    cy() {
      return this.big ? 38 : 9;
    },
    r() {
      return this.big ? 38 : 8;
    },
  },
};
</script>

<style scoped lang="scss">
svg {
  position: relative;
  animation: rotate 0.6s linear infinite;
}

circle {
  width: 100%;
  height: 100%;
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  transform: translate(1px, 1px);
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  animation: animate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0%,
  100% {
    stroke-dashoffset: 50;
  }

  50% {
    stroke-dashoffset: 1;
  }

  50.1% {
    stroke-dashoffset: 100;
  }
}

.icon-progress--big {
  circle {
    stroke-dasharray: 250;
    stroke-dashoffset: 250;
    stroke-width: 4px;
    transform: translate(5px, 5px);
    animation: animate-big 2s linear infinite;
  }
}

@keyframes animate-big {
  0%,
  100% {
    stroke-dashoffset: 250;
  }

  50% {
    stroke-dashoffset: 1;
  }

  50.1% {
    stroke-dashoffset: 500;
  }
}
</style>
