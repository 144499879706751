<template>
  <label
    class="cdek-checkbox-v2"
    :class="{
      'cdek-checkbox-v2--big': big,
      'cdek-checkbox-v2--disabled': disabled,
    }"
  >
    <input
      class="cdek-checkbox-v2__input"
      type="checkbox"
      :checked="modelValue"
      :disabled="disabled"
      @change="onChange"
    />

    <span
      class="cdek-checkbox-v2__checkmark"
      :class="{ 'cdek-checkbox-v2__checkmark_error': validRes }"
    />

    <span class="cdek-checkbox-v2__text">
      <slot />

      <span v-if="$slots.tip" class="cdek-checkbox-v2__tip">
        <slot name="tip" />
      </span>
    </span>
  </label>
</template>

<script lang="ts" setup>
withDefaults(
  defineProps<{
    disabled?: boolean;
    big?: boolean;
    modelValue?: boolean;
    validRes?: boolean;
  }>(),
  {
    disabled: false,
    big: false,
  },
);

const emit = defineEmits(['update:modelValue']);

const onChange = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).checked);
};
</script>

<style lang="scss" scoped>
.cdek-checkbox-v2 {
  display: flex;
  position: relative;
  cursor: pointer;
  gap: 16px;
}

.cdek-checkbox-v2__checkmark {
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  background: $Primary_5;
  border: 1px solid $Primary;
  border-radius: 6px;
  flex: 0 0 24px;

  &_error {
    background: $Error_20;
    border-color: $Error;
    border-width: 2px;
  }

  .cdek-checkbox-v2--big & {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    flex: 0 0 32px;
  }

  .cdek-checkbox-v2:hover & {
    background: $Primary_20;
    border-color: $Primary;
  }

  .cdek-checkbox-v2--disabled &,
  .cdek-checkbox-v2--disabled:hover & {
    background: $Button_Disable;
    border: 1px solid transparent;
  }
}

.cdek-checkbox-v2__checkmark::after {
  content: '';
  position: absolute;
  display: none;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 13px;
  height: 9px;
  background-image: url('./icons/check.svg?url');
  background-size: cover;

  .cdek-checkbox-v2--big & {
    width: 17px;
    height: 12px;
  }
}

.cdek-checkbox-v2__input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;

  &:checked ~ .cdek-checkbox-v2__checkmark {
    background: $Primary;
    border: 1px solid transparent;

    &::after {
      display: block;
    }

    .cdek-checkbox-v2:hover & {
      background: $Primary_70;
      border: 1px solid transparent;
    }

    .cdek-checkbox-v2--disabled &,
    .cdek-checkbox-v2--disabled:hover & {
      background: $Button_Disable;
      border: 1px solid transparent;
    }
  }
}

.cdek-checkbox-v2__text {
  @include body-2;

  margin-top: 2px;

  .cdek-checkbox-v2--big & {
    @include body-1;

    margin-top: 4px;
  }

  display: block;

  .cdek-checkbox-v2--disabled &,
  .cdek-checkbox-v2--disabled:hover & {
    color: $Bottom_60;
  }
}

.cdek-checkbox-v2__tip {
  @include caption-1;

  display: block;
  color: $Bottom_60;
  margin-top: 2px;
}
</style>
